import {
  IoAlertOutline,
  IoCalendarNumberOutline,
  IoCalendarOutline,
  IoCallOutline,
  IoCashOutline,
  IoCheckmark,
  IoCheckmarkCircleOutline,
  IoCheckmarkDoneOutline,
  IoCloseCircleOutline,
  IoFlagOutline,
  IoHeartOutline,
  IoHelpCircleOutline,
  IoHourglassOutline,
  IoLogoUsd,
  IoMedalOutline,
  IoNewspaperOutline,
  IoPersonOutline,
  IoReceiptOutline,
  IoShieldCheckmarkOutline,
  IoThumbsDownOutline,
  IoTimeOutline,
  IoWalletOutline,
  IoCheckmarkCircleSharp,
  IoChevronDownOutline,
  IoEyeOutline,
  IoClose,
  IoStar,
  IoStarHalf,
  IoStarOutline,
  IoCaretDown,
  IoSearchCircle,
  IoCaretDownOutline,
  IoSearch,
  IoCalendar,
  IoCloudUploadOutline,
  IoRemoveCircle,
  IoTrendingUp,
  IoTrendingDown,
  IoChevronDown,
  IoLockClosed,
  IoAdd,
  IoRemove,
  IoStatsChart,
  IoStatsChartOutline,
  IoCalendarNumber,
  IoTime,
  IoConstruct,
  IoPeopleOutline,
  IoSettingsOutline,
  IoWallet,
  IoArrowUp,
  IoArrowDown,
  IoPerson,
  IoCubeOutline,
  IoPieChartOutline,
  IoArrowDownCircleOutline,
  IoArrowUpCircleOutline,
  IoAddCircleOutline,
  IoColorWandOutline,
  IoSettings,
  IoArrowForward,
} from "react-icons/io5";
import { FaRegFrown, FaRegMeh, FaRegSmile } from "react-icons/fa";

const icons = {
  IoAlertOutline,
  IoCalendarNumberOutline,
  IoCalendarNumber,
  IoCalendarOutline,
  IoCallOutline,
  IoCashOutline,
  IoCheckmark,
  IoCheckmarkCircleOutline,
  IoCheckmarkDoneOutline,
  IoCloseCircleOutline,
  IoFlagOutline,
  IoHeartOutline,
  IoHelpCircleOutline,
  IoHourglassOutline,
  IoLogoUsd,
  IoMedalOutline,
  IoNewspaperOutline,
  IoPersonOutline,
  IoReceiptOutline,
  IoShieldCheckmarkOutline,
  IoThumbsDownOutline,
  IoTimeOutline,
  IoWalletOutline,
  IoCheckmarkCircleSharp,
  IoChevronDownOutline,
  IoEyeOutline,
  IoClose,
  IoStar,
  IoStarHalf,
  IoStarOutline,
  IoCaretDown,
  IoSearchCircle,
  IoCaretDownOutline,
  IoSearch,
  IoCalendar,
  IoCloudUploadOutline,
  IoRemoveCircle,
  IoTrendingUp,
  IoTrendingDown,
  IoChevronDown,
  IoLockClosed,
  IoAdd,
  IoRemove,
  IoStatsChart,
  IoTime,
  IoConstruct,
  IoPeopleOutline,
  IoSettingsOutline,
  IoStatsChartOutline,
  IoWallet,
  IoArrowUp,
  IoArrowDown,
  IoPerson,
  IoCubeOutline,
  IoPieChartOutline,
  IoArrowDownCircleOutline,
  IoArrowUpCircleOutline,
  IoAddCircleOutline,
  IoColorWandOutline,
  IoSettings,
  IoArrowForward,
  // Font Awesome 5
  FaRegFrown,
  FaRegMeh,
  FaRegSmile,
};

export default icons;
